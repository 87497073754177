/**
 * Implement Gatsby's Browser APIs in this file.
 * - runs in browser
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * You can delete this file if you're not using it
 */

import React from 'react';
import GlobalContextProvider from './src/context/GlobalContextProvider';

// // prismjs styles
// // import "prismjs/themes/prism-tomorrow.css";

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};
// 